import React from 'react'
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby"


const HomeCategoryList = () => {

    const CategoryQuery = () => {
        return (
            <StaticQuery
              query={graphql`
              {
                allMarkdownRemark(filter: {frontmatter: {type: {eq: "category"}}},  sort: {fields: frontmatter___sortorder}) {
                  edges {
                    node {
                      frontmatter {
                        url
                        htmltitle
                        homeimage {
                            childImageSharp {
                                
                                resize(width:385, height: 280) {
                                    src
                                }
                   
                            }
                            publicURL
                        }
                      }
                    }
                  }
                }
              }
              `}
              render={data => (
                    
                <CategoryList data={data}/>
              )}
            />
          )
    }  

    //dont know if this is the best way to do this but running out of time
    const CategoryList = ({data}) => {
        console.log(data)
        return data.allMarkdownRemark.edges.map((e,i) => 
        <li key={"submenu-"+ i}>
            <Link to={`/${e.node.frontmatter.url}`}>
                <img src={e.node.frontmatter.homeimage.childImageSharp.resize.src} />
                <h4 dangerouslySetInnerHTML={{__html: e.node.frontmatter.htmltitle}}></h4>
            </Link>
        </li>)
    }


    return (

        <div className="category-list">
            <ul>
                <CategoryQuery />
            </ul>
           
        </div>
       
       
    )

}

export default HomeCategoryList