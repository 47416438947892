import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeCategoryList from '../components/homecategorylist'
import { graphql } from "gatsby"
import Loadable from "@loadable/component"

const IndexPage = ({data}) => {
    
    const HomeTopComponent = Loadable(() => import("../components/hometop"));
    return(

        <Layout pageClass='home-page'>
        <SEO title="Home" />
        
            
            <main className='main'>
                
                <HomeTopComponent />
                
                <div className='typography inner'>
                    <div className="intro">
                        <h2>YOUR CODING <span className="red">SPECIALIST</span> AND <span className="red">PARTNER</span></h2>
                        <p>
                        Thank you for the opportunity to show you our extensive product range and services tailor-made to suit your coding application and coding consumable requirements regardless of the make and model of your coders. No matter how <span className="red bold">BIG OR SMALL</span> your coding requirements are….
                        </p>
                        <h2>....WE HAVE <span className="red">THE SOLUTION!</span></h2>
                    </div>
                    <HomeCategoryList />
                </div>
            </main> 
        </Layout>

    )
}
export const pageQuery = graphql`{
    imageSharp(resolutions: {originalName: {eq: "Office-Sign-AU-crop.jpg"}}) {
 
        mobile: resize(width: 460, height: 280, quality: 100) {
            src
        }
    }
  }
`

export default IndexPage